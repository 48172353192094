import * as yup from 'yup'

class ValidationService {
  Regex = {
    mailValidation: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }

  preset = {
    percentage: yup.number().nullable().required().min(0).max(100),
    numericAmount: yup.number().nullable().required().min(0).max(10000000000),
    alphabetical: yup.string().nullable().required().max(20000),
    dateTime: yup.date().nullable().required(),
    checkbox: yup.boolean()
  };

  createUpdateSpecification = yup.object({
    //
  })

  createUpdateProduct = yup.object({
    // ShopName: yup.string('').nullable().required().label('Shop Name').max(100),
    // Services: yup.array().required().nullable().label('Service').min(1, 'Select at least one option of your interest'),
    // ContactName: yup.string('').nullable().required('* required').label('Contact person\'s name').max(100),
    // ContactPhone: yup.string('').nullable().required('* required').label('Contact person\'s phone').max(100),
    // ContactEmail: yup.string('').nullable().email().required('* required').label('Contact person\'s email').max(100)
    //
    // this.id = 0
    // this.name = ''
    // this.name_bn = ''
    // this.overview = ''
    // this.overview_bn = ''
    // this.feature = ''
    // this.feature_bn = ''
    // this.created_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    // this.updated_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
  })
}
export default new ValidationService()
