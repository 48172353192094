import Config from './config'
import FileModel from '@/models/FileModel'
var api = Config.api

const fileAPIRoot = api.BASE_URL + '/file'

class FileService {
  uploadFile (FileModel) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.postCallMultipartFormData(fileAPIRoot + '/upload', FileModel, options, api.ShowAlert, api.ShowAlert)
  }

  getFiles () {
    return api.getCall(fileAPIRoot, api.DoNothing, api.ShowAlert)
  }

  prepareFileForUpload (rawUploadedFiles, referenceId, referenceType) {
    const reader = []
    const preparedFiles = []
    return new Promise((resolve, reject) => {
      for (let i = 0; i < rawUploadedFiles.length; i++) {
        reader[i] = new FileReader()
        reader[i].onload = () => {
          const extension = rawUploadedFiles[i].name.split('.').pop()
          const title = rawUploadedFiles[i].name.replace('.' + extension, '')
          const fileModel = new FileModel().new(0, title, reader[i].result.split(',')[1], rawUploadedFiles[i].type, extension, referenceId, referenceType)
          preparedFiles.push(fileModel)
          if (preparedFiles.length === rawUploadedFiles.length) {
            resolve(preparedFiles)
          }
        }
        reader[i].onerror = reject
        reader[i].readAsDataURL(rawUploadedFiles[i])
      }
    })
  }
}
export default new FileService()
