<template>
  <div class="page-heading">
    <div class="page-title">
      <div class="row">
        <div class="col-12 col-md-6 order-md-1 order-last">
          <h3>All Files</h3>
          <p class="text-subtitle text-muted">View your added files and add new ones</p>
        </div>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav aria-label="breadcrumb" class="breadcrumb-header float-start float-lg-end">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }" >Dashboard</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">All Uploaded Files</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <!-- // Basic multiple Column Form section start -->
    <section id="multiple-column-form">
      <div class="row match-height">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <button @click="openAddForm=!openAddForm" class="card-title btn btn-outline-dark">Add File</button>
            </div>
            <div v-show="openAddForm" class="card-content">
              <div class="card-body">
                <Form class="form" v-slot="{ handleSubmit }" @submit.prevent="createOrUpdateFile" :validation-schema="ValidationSchema">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">File</label>
                        <Field type="text" class="form-control" v-model="uploadedFile" name="file" placeholder="Upload file" />
                        <ErrorMessage class="text-danger" name="title" />
                      </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                      <button class="btn btn-primary me-1 mb-1" v-show="!FileModel.id" @click="handleSubmit($event, createOrUpdateFile)">Create</button>
                      <button class="btn btn-primary me-1 mb-1" v-show="FileModel.id" @click="handleSubmit($event, updateProperty)">Update</button>
                      <button class="btn btn-light-secondary me-1 mb-1" @click="reset">Reset</button>
                      <button class="btn btn-light-danger me-1 mb-1" @click="openAddForm=false">Cancel</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class="section">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-12">
              <button class="badge bg-success" @click='loadDataTable' v-show="false">
                <i class="fa fa-magic"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped" id="table1">
            <thead>
            <tr>
              <th width="5%">#</th>
              <th width="10%">Type</th>
              <th width="55%">Title</th>
              <th width="20%">Preview</th>
              <th width="5%">Extension</th>
              <th width="5%">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(file, index) in files" :key="index">
              <td>{{ index+1 }}</td>
              <td>{{ file.type.toLocaleUpperCase() }}</td>
              <td>{{ file.title.toLocaleUpperCase() }}</td>
              <td><img class="img-preview-thumb" :src="file.url" :alt="file.title.toLocaleUpperCase()"></td>
              <td>{{ file.extension.toLocaleUpperCase() }}</td>
              <td>
                <button v-if="files.length" class="badge bg-info ml-5" @click="copyToClipboard(file.url)">Copy URL</button>
              </td>
            </tr>
            <tr v-if="!files.length">
              <td colspan="7" class="text-center">No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </section>
    <!-- Basic Tables end -->
  </div>

</template>

<style scoped>
  .img-preview-thumb{
    max-height: 100px;
    max-width: 100px;
  }
</style>

<script>

import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import FileService from '../../services/file.service'
import ValidationService from '../../services/validation.service'
import FileModel from '../../models/FileModel'

export default {
  name: 'File',
  data () {
    return {
      ValidationSchema: ValidationService.createUpdateProperty,
      files: [],
      FileModel: new FileModel(),
      openAddForm: false,
      inTableProperties: []
    }
  },
  components: { Form, Field, ErrorMessage },
  mounted () {
    this.getProperties()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    getProperties () {
      this.files = []
      FileService.getFiles().then(
        response => {
          if (response.data.status) {
            console.log(response.data)
            this.files = _.map(response.data.objects.files, function (p) {
              return new FileModel().load(p)
            })
            this.inTableProperties = this.files
          }
        }
      )
    },
    copyToClipboard (text) {
      try {
        navigator.clipboard.writeText(text)
      } catch (err) {
        alert('Failed to copy URL into clipboard. Reason: ', err)
      }
    },
    createOrUpdateFile () {
      const vm = this
      var formData = new FormData()
      var imagefile = document.querySelector('#file')
      formData.append('image', imagefile.files[0])

      FileService.uploadFile(formData).then(
        response => {
          if (response.data.status) {
            vm.files.push(new FileModel().load(response.data.objects.property))
            vm.reset()
          }
        }
      )
    },
    reset () {
      this.FileModel = new FileModel()
    }
  }

}
</script>
