import UtilityService from '../services/utility.service'

export default class FileModel {
  constructor () {
    this.id = 0
    this.title = ''
    this.url = ''
    this.type = ''
    this.extension = ''
    this.ref_id = 0
    this.ref_type = ''
    this.created_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
  }

  load (data, ProductId) {
    this.id = data.id
    this.title = data.title
    this.url = data.url
    this.type = data.type
    this.extension = data.extension
    this.ref_id = data.ref_id
    this.ref_type = data.ref_type
    this.created_at = UtilityService.formatDateTime(data.created_at, 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(data.updated_at, 'MM/DD/YY hh:mm A')
    return this
  }

  new (id, title, url, type, extension, refId, refType) {
    this.id = id
    this.title = title
    this.url = url
    this.type = type
    this.extension = extension
    this.ref_id = refId
    this.ref_type = refType
    this.created_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    return this
  }
}
